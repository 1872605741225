import { ReactNode, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Center, Box, VStack, Spinner, Image } from '@chakra-ui/react';
import useAxios from '../hooks/useAxios';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';

/*
const videoPaths = [
  'https://putiruleta.com/files/7afa6202-41bf-4884-801c-ddb1050dc6a4/66d10e1b-fb6c-4864-9ab9-d5b892fead49.mp4',
  'https://putiruleta.com/files/b5c8e7ee-b767-4e25-b7fb-4960c70dbf0c/1826a0c2-f0e5-4b93-9e56-57ca539585ef.mp4',
  'https://putiruleta.com/files/66111b05-546a-45d0-924c-38c645c20003/700904b6-4491-4d0c-8ad8-76157a6ac30f.mp4',
  'https://putiruleta.com/files/ac8edd19-3dcb-493b-83a1-240d15e1d722/346d2ad5-967a-40c1-983b-53987a6817fa.mp4',
  'https://putiruleta.com/files/a5927b45-60e3-49d1-85e5-48d862a0b72d/IMG_2561.MOV',
  'https://putiruleta.com/files/8bf62ce2-7e33-4ec0-9c1e-fba332a1e4a6/Duo Corto.mp4',
  'https://putiruleta.com/files/4417909d-d878-4ab4-9635-4349df83f60d/IMG_3408.MOV',
  'https://putiruleta.com/files/cccb669e-f243-46be-8bc3-c7ec5ffb9522/IMG_3409.MOV',
  'https://putiruleta.com/files/063f8e70-17a6-44da-9e53-e630cf9d609c/1.MP4',
  'https://putiruleta.com/files/ea9168a7-6679-428d-802e-6bf741b13064/2.MP4',
  'https://putiruleta.com/files/daa25bf5-5053-49a4-acdc-f503f9b2f17c/3.MP4',
  'https://putiruleta.com/files/577d6c24-47cf-4a40-abb6-3ef594fa3724/4.MP4',
  'https://putiruleta.com/files/b64ec933-4a8e-41a8-a38e-357ecbe7cd58/5.MP4',
  'https://putiruleta.com/files/b807dcbd-daf9-41d3-8a02-10b270b47302/6.MP4',
  'https://putiruleta.com/files/80e0e9e7-ab43-47d2-8b5c-3640814beb78/7.MP4',
  'https://putiruleta.com/files/714933d6-f711-400e-9491-4fbae87ca1e7/c8d25aa9-ceb4-4136-8899-0abf61f5c2f3.mp4',
  'https://putiruleta.com/files/baafcb48-eebd-47d5-b9eb-9205eb13880b/1a4fd7f2-2fbd-4d91-b23b-0fb48e91d680.mp4',
  'https://putiruleta.com/files/2ac15b15-6ee3-47cb-acc3-8fb263380247/5c685378-05a8-4513-95be-3f86ba8225d0.mp4',
  'https://putiruleta.com/files/d1585450-4b1c-4f3a-bb2f-749ba9fa2151/3e47deb4-4692-4a87-bee8-3bc41cef9c09.mp4',
];
*/

function Player() {
  const [videoPaths, setVideoPaths] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  let params = useParams();
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/cargar-pago/' + params.payment_id,
  });

  useEffect(() => {
    if (response !== null) {
      if (response.respuesta) {
        const medios = response.medios;
        const tmpArry = [];
        const tmpArry2 = [];
        medios.map(item => {
          var fileName = item.uploadName;
          if (
            fileName.toLowerCase().endsWith('.mp4') ||
            fileName.toLowerCase().endsWith('.avi') ||
            fileName.toLowerCase().endsWith('.mkv') ||
            fileName.toLowerCase().endsWith('.mov') ||
            fileName.toLowerCase().endsWith('.wmv')
          ) {
            var tmpPath =
              'https://estoy.live/files/' + item.uuid + '/' + fileName;
            tmpArry.push(tmpPath);

            setVideoPaths(tmpArry);
          } else {
            var tmpPath =
              'https://estoy.live/files/' + item.uuid + '/' + fileName;
            tmpArry2.push(tmpPath);
          }
          setImagePaths(tmpArry2);
        });
      }
    }
  }, [response]);

  return (
    <Accordion allowToggle>
      {imagePaths.map((imagePath, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Image {index + 1}
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Image src={imagePath} width="100%" />
          </AccordionPanel>
        </AccordionItem>
      ))}
      {videoPaths.map((videoPath, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Video {index + 1}
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <ReactPlayer url={videoPath} controls width="100%" />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default Player;
