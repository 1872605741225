import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Image,
  FormControl,
  FormLabel,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import { useAppContext } from '../AppContext';

function MediaLibrary({ thumbs }) {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    new Array(thumbs.length).fill({ selected: false, id: null })
  );

  const [loadedImages, setLoadedImages] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    let imageCount = 0;
    thumbs.forEach((item, i) => {
      const img = new window.Image();
      img.src = item.src;
      img.onload = () => {
        imageCount++;
        if (imageCount === thumbs.length) {
          setAllImagesLoaded(true);
        }
      };
    });
    console.log(thumbs);
  }, [thumbs]);

  const handleImageClick = (index, id) => {
    const updatedCheckboxes = [...selectedCheckboxes];
    updatedCheckboxes[index] = {
      selected: !updatedCheckboxes[index].selected,
      id: id,
    };
    setSelectedCheckboxes(updatedCheckboxes);
    setSelectedThumbs(updatedCheckboxes);
  };

  const { selectedThumbs, setSelectedThumbs } = useAppContext();

  return (
    <>
      <SimpleGrid columns={[3, 2, 2]} spacing={1} p={5}>
        {thumbs.map((item, i) =>
          allImagesLoaded ? (
            <Box
              key={i}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              backgroundColor={'black'}
            >
              <Image
                src={item.src}
                w={'100%'}
                alt="Imagen"
                onClick={() => handleImageClick(i, item.id)}
                maxW="100%"
                maxH="100%"
              />

              <FormControl
                position="absolute"
                bottom="0"
                right="0"
                w={'auto'}
                paddingRight="4px"
                style={{ pointerEvents: 'none' }}
              >
                <Checkbox
                  id={`checkbox-${i}`}
                  name={`checkbox-${i}`}
                  value={item.id}
                  isChecked={selectedCheckboxes[i]?.selected}
                  w={'100%'}
                />
              </FormControl>
            </Box>
          ) : (
            <Box key={i}>
              <Spinner size="md" color="black" />
            </Box>
          )
        )}
      </SimpleGrid>
    </>
  );
}

export default React.memo(MediaLibrary);
