import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import TelegramLoginSubscriptor from './TelegramLoginSubscriptor';
import React, { useState, useEffect } from 'react';

export default function SaleCard({ children }) {
  const [urlFoto, setUrlFoto] = useState();
  const url =
    'https://estoy.live/datos-user/' +
    localStorage.plan_pic +
    '-1.jpg?timestamp=' +
    new Date().getTime();
  useEffect(() => {
    setUrlFoto(url);
    console.log(url);
  }, [urlFoto, setUrlFoto]);

  return (
    <Center py={6}>
      <Box
        maxW={['235px', '300px', '470px']}
        minW={['220px', '220px', '400px']}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
      >
        <Image
          h={'120px'}
          w={'full'}
          src={'https://estoy.live/sale-card-background.png'}
          objectFit="cover"
          alt="#"
        />
        <Flex justify={'center'} mt={-12}>
          <Avatar
            size={'xl'}
            src={urlFoto}
            css={{
              border: '2px solid white',
            }}
          />
        </Flex>
        <Box p={6}>{children}</Box>
      </Box>
    </Center>
  );
}
