import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Image,
  FormControl,
  FormLabel,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import { useAppContext } from '../AppContext';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SliderPreview({ hash }) {
  const [loadedImages, setLoadedImages] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [thumbs, setThumbs] = useState([]);
  useEffect(() => {
    async function getThumbs() {
      axios
        .get('https://server-mb9m.onrender.com/get-preview-thumbs/' + hash)
        .then(res => {
          console.log(res.data.respuesta);
          localStorage.setItem(
            'telegram_id',
            res.data.respuesta[0].telegram_id
          );
          const images = [];
          const imagesArry = res.data.respuesta;
          imagesArry.map((item, i) => {
            var fileName = item.uploadName;
            if (
              fileName.endsWith('.mp4') ||
              fileName.endsWith('.avi') ||
              fileName.endsWith('.mkv') ||
              fileName.endsWith('.mov') ||
              fileName.endsWith('.wmv')
            ) {
              // Reemplaza la extensión por "jpg"
              var newFileName = fileName.replace(/\.[^.]+$/, '.jpg');
            } else {
              // No es un archivo de video, mantener el nombre de archivo original
              var newFileName = fileName;
            }
            images.push(
              'https://estoy.live/files/' + item.uuid + '/thumb-' + newFileName
            );
            setThumbs(images);
            setAllImagesLoaded(true);
          });
        });
    }
    getThumbs();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div
      style={{
        display: 'block',
        height: '100%',
        margin: '0 auto',
      }}
    >
      <Slider {...settings}>
        {thumbs.map((image, index) => (
          <div key={index}>
            <Image
              margin={'0 auto'}
              src={image}
              alt={`Slide ${index}`}
              onLoad={() => console.log('La imagen se ha cargado')}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default React.memo(SliderPreview);
