import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Center,
  VStack,
} from '@chakra-ui/react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SliderPreview from './SliderPreview';
import SaleCardWrapper from './SaleCardWrapper';

const InfoProducto = () => {
  let params = useParams();
  const [telegramId, setTelegramId] = useState(
    localStorage.telegram_id || null
  );

  const [loading, setLoading] = useState(false);

  const handlePagar = () => {
    setLoading(true);
    axios
      .get('https://server-mb9m.onrender.com/generar-link/' + params.hash)
      .then(res => {
        console.log(res);
        setLoading(false);
        window.location.href = res.data.respuesta.redirect_url;
      });
  };

  return (
    <SaleCardWrapper>
      <Box width="100%" p={'25px'} boxShadow="md" borderRadius="md" bg="white">
        <SliderPreview hash={params.hash} />
      </Box>
      <Box width="100%" p={'25px'} boxShadow="md" borderRadius="md" bg="white">
        <Button onClick={handlePagar} isLoading={loading}>
          Pagar
        </Button>
      </Box>
    </SaleCardWrapper>
  );
};

export default InfoProducto;
