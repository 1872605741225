import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Grid,
  extendTheme,
  StyleFunctionProps,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Home from './components/Home';
import LandingPage from './components/LandingPage';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import InfoModal from './components/InfoModal';
import Login from './components/Login';
import Invite from './components/Invite';
import Player from './components/Player';
import InfoProducto from './components/InfoProducto';
import Dashboard from './Dashboard';
import { AuthProvider, useAuth } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import { AppProvider } from './AppContext';

NiceModal.register('infomodal', InfoModal);
const theme = extendTheme({
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        fontFamily: "'Poppins', sans-serif !important",
      },
      h1: {
        fontFamily: "'Poppins', sans-serif !important",
        fontSize: '20px !important',
      },
      h2: {
        fontFamily: "'Poppins', sans-serif !important",
        fontSize: '18px !important',
      },
      p: {
        fontFamily: "'Poppins', sans-serif !important",
      },
    }),
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppProvider>
        <AuthProvider>
          <NiceModal.Provider>
            <Box textAlign="center" fontSize="xl">
              <Grid minH="100vh">
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/:hash" element={<Home />} />
                  <Route path="/player/:payment_id" element={<Player />} />
                  <Route
                    path="/infoproducto/:hash"
                    element={<InfoProducto />}
                  />
                  <Route path="/invite/:telegram_id" element={<Invite />} />
                  <Route path="/login" element={<LoginWrapper />} />
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </Grid>
            </Box>
          </NiceModal.Provider>
        </AuthProvider>
      </AppProvider>
    </ChakraProvider>
  );
}

function LoginWrapper() {
  const { login } = useAuth();

  const handleLogin = newToken => {
    login(newToken);
  };

  return <Login onLogin={handleLogin} />;
}

export default App;
